import React from 'react';

import Metadata from './Metadata';
import Profile from './Profile';
import { HTTPError } from '@utils';

import { formatRel } from '@components/users/utils';

import {
  useLocation,
  useDashboardSWR,
  useDashboardCRUD,
  useToast,
  useFeatureFlags,
} from '@hooks';

import { Organization } from '@types';
import { ProfileForm } from './types';
import LogoUploader from './LogoUploader';

import { makeOrganizationPaths } from '../../utils';

import Form from './Form';

const Tab = (): JSX.Element => {
  const { instanceId, query } = useLocation();
  const { organizationIDorSlug } = query;
  const {
    featureFlags: { allow_org_admin_delete },
  } = useFeatureFlags();

  const { logoPath, basePath } = makeOrganizationPaths(
    instanceId,
    organizationIDorSlug as string,
  );

  const {
    data: organization,
    mutate: mutateOrganization,
    error: organizationError,
  } = useDashboardSWR<Organization>(() => {
    if (!instanceId || !organizationIDorSlug) {
      return null;
    }
    return basePath;
  });
  const { showSuccessToast, showErrorToast } = useToast();

  const { update } = useDashboardCRUD<Organization>();

  const updateLogo = async (
    changes: Pick<ProfileForm, 'logo_url' | 'logo_image_id'>,
  ) => {
    try {
      await update(logoPath, changes);
      const updated = { ...organization, ...changes };
      void mutateOrganization({ ...updated }, { revalidate: false });
      showSuccessToast('Organization settings saved');
    } catch (_) {
      showErrorToast("Couldn't update the organization logo");
    }
  };

  const updateMetadata = async (
    changes: Pick<ProfileForm, 'private_metadata' | 'public_metadata'>,
  ) => {
    try {
      await update(basePath, changes);
      const updated = { ...organization, ...changes };
      void mutateOrganization({ ...updated }, { revalidate: false });
      showSuccessToast('Organization metadata saved');
    } catch (_) {
      showErrorToast("Couldn't update the organization metadata");
    }
  };

  const updateForm =
    (formReset, formError) =>
    async (
      changes: Pick<
        ProfileForm,
        'max_allowed_memberships' | 'name' | 'admin_delete_enabled'
      >,
    ) => {
      const { max_allowed_memberships, name, admin_delete_enabled } = changes;
      try {
        await update(basePath, {
          max_allowed_memberships,
          name,
          ...(allow_org_admin_delete ? { admin_delete_enabled } : {}),
        });
        const updated = Object.assign({}, organization, changes);
        void mutateOrganization({ ...updated }, { revalidate: false });
        showSuccessToast('Organization settings saved');
        formReset({}, { keepValues: true });
      } catch (error) {
        if (error?.name === 'HTTPError') {
          (error as HTTPError)?.fieldErrors.forEach(err => {
            const param = err?.meta?.param_name as keyof ProfileForm;
            if (param) {
              formError(param, { type: 'api', message: err.message });
            }
          });
        }
      }
    };

  return (
    <Form
      hasDataLoaded={!!organization}
      name={organization.name}
      max_allowed_memberships={organization.max_allowed_memberships}
      admin_delete_enabled={organization.admin_delete_enabled}
      metadata={
        <Metadata
          update={updateMetadata}
          private_metadata={organization.private_metadata}
          public_metadata={organization.public_metadata}
          hasError={organizationError}
          mutate={mutateOrganization}
        />
      }
      profile={
        <Profile
          slug={organization?.slug}
          logoUploader={
            <LogoUploader
              logoURL={organization.logo_url}
              updateLogo={updateLogo}
            />
          }
          createdAt={formatRel(organization.created_at)}
          id={organization.id}
          hasError={organizationError}
          mutate={mutateOrganization}
        />
      }
      updateForm={updateForm}
    />
  );
};

export default React.memo(Tab);
