import React from 'react';
import { SamlConnectionTable } from './SamlConnectionTable';
import { VStack, Box, HStack, Button } from '@chakra-ui/react';
import Filters from './Filters';
import { PlusIcon } from '@heroicons/react/solid';
import { useLocation, useSupportedFeature } from '@hooks';
import { useRouter } from 'next/router';
import { SUPPORTED_FEATURES } from '@constants';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { SamlConnectionEnablePrompt } from './SamlConnectionEnablePrompt';

export function SamlConnectionList(): JSX.Element {
  const router = useRouter();
  const { showModal } = usePaymentRequired();
  const { applicationId, instanceId } = useLocation();
  const { isSupported, hasNotSeenInfoBillingModal } = useSupportedFeature();

  const feature = SUPPORTED_FEATURES.saml;

  const handleCreateConnection = () => {
    if (!isSupported(feature)) {
      return showModal({
        features: [feature],
      });
    }

    if (hasNotSeenInfoBillingModal(feature)) {
      showModal({
        callbackAfterClose: () => {
          void router.push(
            `/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections/new`,
          );
        },
      });
    } else {
      void router.push(
        `/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections/new`,
      );
    }
  };

  return (
    <VStack
      alignItems='self-start'
      spacing={2}
      width='100%'
      flexDirection='column'
    >
      {isSupported(feature) && (
        <HStack justifyContent='space-between' width='100%'>
          <Filters />
          <Button
            aria-label='create connection'
            leftIcon={<PlusIcon width={16} height={16} color='white' />}
            _hover={{
              color: 'white',
            }}
            ml='auto'
            onClick={handleCreateConnection}
          >
            Create connection
          </Button>
        </HStack>
      )}

      <Box width='100%'>
        {!isSupported(feature) ? (
          <SamlConnectionEnablePrompt />
        ) : (
          <SamlConnectionTable />
        )}
      </Box>
    </VStack>
  );
}
