import React from 'react';

import { CardDualPanel } from '@components/common';
import { Stack } from '@chakra-ui/react';
import { useDashboardSWR, useInstance } from '@hooks';

import { InputPreview } from './InputPreview';

export function HostedPages(): JSX.Element {
  const { instance } = useInstance();
  const accountsPortalUrl = instance?.active_domain?.accounts_portal_url;

  const { data: displayConfig } = useDashboardSWR(() =>
    instance ? `/v1/instances/${instance?.id}/display_config` : null,
  );

  const signUpUrl = displayConfig?.sign_up_path
    ? `${accountsPortalUrl}${displayConfig?.sign_up_path}`
    : displayConfig?.sign_up_url;

  const signInUrl = displayConfig?.sign_in_path
    ? `${accountsPortalUrl}${displayConfig?.sign_in_path}`
    : displayConfig?.sign_in_url;

  const userProfileUrl = displayConfig?.user_profile_path
    ? `${accountsPortalUrl}${displayConfig?.user_profile_path}`
    : displayConfig?.user_profile_url;

  const organizationsEnabled = !!instance?.organization_settings.enabled;

  const organizationProfileUrl = displayConfig?.organization_profile_path
    ? `${accountsPortalUrl}${displayConfig?.organization_profile_path}`
    : displayConfig?.organization_profile_url;

  const createOrganizationUrl = displayConfig?.create_organization_path
    ? `${accountsPortalUrl}${displayConfig?.create_organization_path}`
    : displayConfig?.default_create_organization_url;

  return (
    <CardDualPanel
      bg='gray.50'
      title='Hosted page URLs'
      subtitle='Visit these URLs to preview your Clerk-hosted components.'
    >
      <Stack spacing='6'>
        <InputPreview label='Sign-up URL' value={signUpUrl} />
        <InputPreview label='Sign-in URL' value={signInUrl} />
        <InputPreview label='User profile URL' value={userProfileUrl} />
        {organizationsEnabled && (
          <InputPreview
            label='Organization profile URL'
            value={organizationProfileUrl}
          />
        )}
        {organizationsEnabled && (
          <InputPreview
            label='Create organization URL'
            value={createOrganizationUrl}
          />
        )}
      </Stack>
    </CardDualPanel>
  );
}
