import React from 'react';

import { preventSubmitOnEnter } from '@utils';

import { useForm, FormProvider } from 'react-hook-form';

import { VStack } from '@chakra-ui/react';

import { StickyBottomCTA } from '@components/common';

import { ProfileForm } from './types';
import { OrganizationsMaxAllowedMembershipsForm } from '@components/organizations/OrganizationsMaxAllowedMembershipsForm';
import { OrganizationPermissions } from '@components/organizations/organizationDetails/ProfileTab/OrganizationPermissions';

type FormProps = {
  metadata: React.ReactElement;
  profile: React.ReactElement;
  name: ProfileForm['name'];
  max_allowed_memberships: ProfileForm['max_allowed_memberships'];
  updateForm: any;
  hasDataLoaded: boolean;
  admin_delete_enabled: boolean;
};

const FORM_ID = 'organization_details';

const Form = ({
  metadata,
  name,
  max_allowed_memberships,
  updateForm,
  hasDataLoaded,
  profile,
  admin_delete_enabled,
}: FormProps): JSX.Element => {
  const methods = useForm<ProfileForm>({ mode: 'onChange' });
  const { isDirty, isSubmitting } = methods.formState;

  const initializeForm = React.useCallback(() => {
    methods.reset({
      max_allowed_memberships,
      name,
      is_unlimited_enabled: max_allowed_memberships === 0,
      admin_delete_enabled,
    });
  }, [name, max_allowed_memberships, admin_delete_enabled]);

  // Initialize form after organization is loaded
  React.useEffect(() => {
    if (hasDataLoaded) {
      initializeForm();
    }
  }, [initializeForm, hasDataLoaded]);

  return (
    <FormProvider {...methods}>
      <form
        id={FORM_ID}
        onSubmit={methods.handleSubmit(
          updateForm(methods.reset, methods.setError),
        )}
        onKeyDown={preventSubmitOnEnter}
        autoComplete='off'
      >
        <VStack spacing='7' align='stretch' mb='7'>
          {profile}
          <OrganizationsMaxAllowedMembershipsForm
            title='Membership limit'
            subtitle='Edit Organization membership limit'
          />
          {metadata}
          <OrganizationPermissions />
          <StickyBottomCTA
            formId={FORM_ID}
            isVisible={isDirty}
            onReset={initializeForm}
            isSubmitting={isSubmitting}
          />
        </VStack>
      </form>
    </FormProvider>
  );
};

export default Form;
