import React from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import {
  CogIcon,
  ColorSwatchIcon,
  HomeIcon,
  KeyIcon,
  LinkIcon,
  LockClosedIcon,
  MapIcon,
  OfficeBuildingIcon,
  PuzzleIcon,
  ServerIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from '@heroicons/react/solid';
import { JwtIcon, WebhooksIcon } from '@clerk-ui/assets/icons';
import {
  useApplication,
  useDashboardSWR,
  useFeatureFlags,
  useInstance,
  useLocation,
} from '@hooks';
import { NavLink } from './NavLink';
import { NavExpandable } from './NavExpandable';
import { ShowInProdOnly } from './control-components';
import { UserSettings } from '@types';
import { BetaBadge, NewBadge, UpdateNeededBadge } from '@components/common';
import { NavItem } from '@components/sidebar/NavItem';
import { useAnalytics } from '@hooks/useAnalytics';

export function Sidebar(): JSX.Element {
  const { track } = useAnalytics();
  const { isHome } = useLocation();
  const { instance } = useInstance();
  const { application } = useApplication();
  const {
    featureFlags: { allow_saml_dashboard },
  } = useFeatureFlags();

  const { data: settings } = useDashboardSWR<UserSettings>(() =>
    instance ? `/v1/instances/${instance?.id}/user_settings` : null,
  );

  if (isHome) {
    return null;
  }

  const trackSegment = (eventName: string, clickedOn: string) => {
    track(eventName, {
      surface: 'Dashboard',
      location: 'Instance Overview',
      clickedOn,
    });
  };

  return (
    <Box as='aside' mr={12}>
      <Stack spacing={8}>
        <Box>
          <NavLink
            icon={HomeIcon}
            href='/'
            label='Home'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Top Menu Item Clicked',
                'Home',
              );
            }}
          />
          <NavLink
            icon={UserGroupIcon}
            href='/users'
            label='Users'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Top Menu Item Clicked',
                'Users',
              );
            }}
          />
          <NavLink
            icon={OfficeBuildingIcon}
            href='/organizations'
            label='Organizations'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Top Menu Item Clicked',
                'Organizations',
              );
            }}
          />
        </Box>

        <Box>
          <Text
            textStyle='xs-semibold'
            color='gray.300'
            textTransform='uppercase'
            mb={2}
          >
            Configure
          </Text>
          <NavExpandable icon={ShieldCheckIcon} label='User & Authentication'>
            <NavLink
              href='/user-authentication/email-phone-username'
              label='Email, Phone, Username'
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'Email, Phone, Username',
                );
              }}
            />

            <NavLink
              href='/user-authentication/social-connections'
              label='Social Connections'
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'Social Connections',
                );
              }}
            />

            {settings?.sign_up.progressive ? (
              <NavLink
                href='/user-authentication/web3'
                label='Web3'
                onClick={() => {
                  trackSegment(
                    'Dashboard_Instance Overview_Configure Menu Item Clicked',
                    'Web3',
                  );
                }}
              />
            ) : (
              <NavItem
                isDisabled
                isAccordionChild
                isExternal={false}
                label={
                  <Box as='span' display='inline-flex'>
                    Web3&nbsp;
                    <UpdateNeededBadge
                      href={`/apps/${application?.id}/instances/${instance?.id}/settings`}
                    />
                  </Box>
                }
              />
            )}

            {allow_saml_dashboard && settings?.sign_up.progressive ? (
              <NavLink
                href='/user-authentication/enterprise-connections'
                label={
                  <Box as='span'>
                    Enterprise Connections
                    <BetaBadge />
                  </Box>
                }
                onClick={() => {
                  trackSegment(
                    'Dashboard_Instance Overview_Configure Menu Item Clicked',
                    'Enterprise Connections',
                  );
                }}
              />
            ) : (
              <NavItem
                isDisabled
                isAccordionChild
                isExternal={false}
                label={
                  <Box as='span'>
                    Enterprise Connections&nbsp;
                    <UpdateNeededBadge
                      href={`/apps/${application?.id}/instances/${instance?.id}/settings`}
                    />
                  </Box>
                }
              />
            )}

            <NavLink
              href='/user-authentication/multi-factor'
              label='Multi-factor'
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'Multi-factor',
                );
              }}
            />
            <NavLink
              href='/user-authentication/restrictions'
              label='Restrictions'
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'Restrictions',
                );
              }}
            />
          </NavExpandable>

          <NavLink
            icon={OfficeBuildingIcon}
            href='/organizations-settings'
            label='Organizations Settings'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Top Menu Item Clicked',
                'Organizations Settings',
              );
            }}
          />

          <NavLink
            icon={LockClosedIcon}
            href='/sessions'
            label='Sessions'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Configure Menu Item Clicked',
                'Sessions',
              );
            }}
          />
          <NavExpandable icon={ColorSwatchIcon} label='Customization'>
            <NavLink
              href='/customization/branding'
              label='Branding'
              onClick={() =>
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'Sessions',
                )
              }
            />
            <NavLink
              href='/customization/hosted-pages'
              label='Hosted Pages'
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'Hosted Pages',
                );
              }}
            />
            <NavLink
              href='/customization/avatars'
              label={
                <Flex gap={2} align='center'>
                  Avatars
                  <NewBadge />
                </Flex>
              }
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'Avatars',
                );
              }}
            />
            <NavLink
              href='/customization/email'
              label='Emails'
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'Emails',
                );
              }}
            />
            <NavLink
              href='/customization/sms'
              label='SMS'
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Configure Menu Item Clicked',
                  'SMS',
                );
              }}
            />
          </NavExpandable>
          <NavLink
            icon={PuzzleIcon}
            href='/integrations'
            label='Integrations'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Configure Menu Item Clicked',
                'Integrations',
              );
            }}
          />
          <NavLink
            icon={JwtIcon}
            href='/jwt-templates'
            label='JWT Templates'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Configure Menu Item Clicked',
                'JWT Templates',
              );
            }}
          />
          <NavLink
            icon={WebhooksIcon}
            href='/webhooks'
            label='Webhooks'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Configure Menu Item Clicked',
                'Webhooks',
              );
            }}
          />
        </Box>

        <Box>
          <Text
            textStyle='xs-semibold'
            color='gray.300'
            textTransform='uppercase'
            mb={2}
          >
            Developers
          </Text>
          <NavLink
            icon={KeyIcon}
            href='/api-keys'
            label='API Keys'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Developers Menu Item Clicked',
                'API Keys',
              );
            }}
          />
          <NavLink
            icon={LinkIcon}
            href='/paths'
            label='Paths'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Developers Menu Item Clicked',
                'Paths',
              );
            }}
          />
          <ShowInProdOnly>
            <NavLink
              icon={ServerIcon}
              href='/dns'
              label='DNS'
              onClick={() => {
                trackSegment(
                  'Dashboard_Instance Overview_Developers Menu Item Clicked',
                  'DNS',
                );
              }}
            />
          </ShowInProdOnly>
        </Box>

        <Box>
          <Text
            textStyle='xs-semibold'
            color='gray.300'
            textTransform='uppercase'
            mb={2}
          >
            Application
          </Text>
          <NavLink
            icon={MapIcon}
            href='/plan-billing'
            label='Plan & Billing'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Application Menu Item Clicked',
                'Plan & Billing',
              );
            }}
          />
          <NavLink
            icon={CogIcon}
            href='/settings'
            label='Settings'
            onClick={() => {
              trackSegment(
                'Dashboard_Instance Overview_Application Menu Item Clicked',
                'Settings',
              );
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
}
